import React from 'react';
import Layout from '../components/layout';

const PersonalInfo = () => (
  <Layout>
    <div className="alt">
      <section id="one">
        <div className="inner">
          <h1>Spracovanie osobných údajov</h1>
          <h4>Úvodné ustanovenie</h4>
          <p>Spracovanie osobných údajov pre účely spoločnosti Marián Kaleta - Transferrum s.r.o. (ďalej len spoločnosť) prebieha v súlade so zákonom o ochrane osobných údajov č. 18/2018 Z. z. Na účel týchto podmienok spracovania osobných údajov sa zákazníkom rozumie osoba ktorej spoločnosť poskytla alebo poskytuje produkt alebo službu (prípadne len informatívnu), alebo osoba ktorá nadviazala so spoločnosťou komunikačný styk v písomnej, telefonickej, alebo inej forme. Spoločnosť spracúva osobné údaje svojich zákazníkov v kontaktnom formulári na svojej internetovej stránke.</p>
          <ol>
            <li>
              <p>
              <strong>Identifikačné údaje spoločnosti:</strong><br />

Marián Kaleta – Transferrum<br />

Budovateľská 18 <br />
064 01 Stará Ľubovňa <br />
IČO: 30614082 <br />
DIČ: 1020762171 <br />
IČ DPH: SK1020762171 <br />
číslo živ. reg.: 710-1500<br />
                </p>      
                <p><strong>Kontaktné údaje prevádzkovateľa:</strong><br />

Meno: Marián Kaleta<br />

Email: info@transferrum.sk<br />

Telefonický kontakt: +421 905 235 468<br />

</p> 
<p><strong>
Kontaktné údaje zástupcu prevádzkovateľa:</strong><br />

Meno: Martin Kaleta<br />

Email: martin@transferrum.sk<br />

Telefonický kontakt: +421 907 094 801</p>        
            </li>
            
            <li><p>Spoločnosť spracúva tieto osobné údaje v kontaktnom formulári na svojej internetovej stránke: titul, meno, priezvisko, telefónne číslo, adresa elektronickej pošty (email) za účelom kontaktovania zákazníka pre zdieľanie informácii o produktoch a službách spoločnosti. Spoločnosť spracuje tieto údaje na základe súhlasu zákazníka ktorý potvrdením v kontaktnom formulári potvrdzuje že bol oboznámený s rozsahom a účelom spracovania údajov. Príjemcami údajov sú prevádzkovateľ spoločnosti a zástupca prevádzkovateľa spoločnosti.
            </p></li>
            
            <li><p>Osobné údaje sa ukladajú na serveroch (počítačoch) prevádzkovateľa elektronickej pošty spoločnosti a na počítačoch a elektronických mobilných zariadeniach zamestnancov spoločnosti. Tieto údaje sa neposkytujú tretím stranám ani sa nevyvážajú do tretích krajín.
            </p></li>
            
            <li><p>Osobné údaje sa uchovávajú na dobu 5 rokov.
            </p></li>
            
            <li><p> Zákazník má právo kedykoľvek požadovať od spoločnosti prístup k svojim osobným údajom na zistenie rozsahu a účelu spracovania osobných údajov.
            </p></li>
            
            <li><p>Zákazník má právo požadovať od spoločnosti opravu alebo aktualizáciu osobných údajov ktorými spoločnosť disponuje.
            </p></li>
            
            <li><p>Zákazník má právo namietať spracovanie osobných údajov.
            </p></li>
            
            <li><p>Zákazník má právo obmedziť rozsah spracovania osobných údajov.
            </p></li>
            
            <li><p>Zákazník má právo požadovať od spoločnosti prenos svojich osobných údajov tretej strane podľa určenia
            </p></li>
            
            <li><p>  Zákazník má právo kedykoľvek odvolať svoj súhlas na spracovanie osobných údajov a požadovať od spoločnosti ich kompletné vymazanie.
            </p></li>
            
            <li><p>  Zákazník má právo začať právne konanie, v zmysle § 100 zákona č. 18/2018 Z. z. ak sa domnieva že bol priamo dotknutý na svojich právach ustanovených zákonom najmä ak spoločnosť konala v rozpore s podmienkami spracovania osobných údajov.

 
            </p></li>
            
            <li><p>  Poskytnutie osobných údajov nie je zákonnou ani zmluvnou požiadavkou. Je podmienkou len pre kompletné vyplnenie a odoslanie kontaktného formulára spoločnosti. V prípade neposkytnutia osobných údajov nebude spoločnosť schopná kontaktovať zákazníka.
            </p></li>
            
            <li><p>  Spoločnosť nevyužíva automatizované individuálne rozhodovanie ani profilovanie pri spracovaní osobných údajov.
            </p></li>
            
            <li><p>   Spoločnosť získava informácia o osobách navštivujúcich internetovu stránku spoločnosti výlučne cez kontaktný formulár. Spoločnosť nepoužíva cookies, nezaznamenáva IP adresy ani nemanipuluje so žiadnými inými identifikačnými údajmi návštevníka internetovej stránky spoločnosti.
            </p></li>

          </ol>
        </div>
      </section>
    </div>
  </Layout>
);

export default PersonalInfo;
